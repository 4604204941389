import {bookingConstants} from '../constants';
import {booking} from "../services/booking";

export const bookingActions = {
    getBookedDays,
    book,
    clear,
};

function getBookedDays(from) {
    return dispatch => {
        dispatch(request());
        booking.getBookedDays(from)
            .then(data => dispatch(success(data)))
            .catch(error => dispatch(failure(error)));
    };

    function request() {
        return {type: bookingConstants.BOOKING_GET_DAYS_REQUEST}
    }

    function success(unavailableDays) {
        return {type: bookingConstants.BOOKING_GET_DAYS_SUCCESS, unavailableDays}
    }

    function failure(error) {
        return {type: bookingConstants.BOOKING_GET_DAYS_FAILURE, error}
    }
}

function book(reservation) {
    return dispatch => {
        dispatch(request());
        booking.book(reservation)
            .then(data => dispatch(success()))
            .catch(error => dispatch(failure(error)));
    };

    function request() {
        return {type: bookingConstants.BOOKING_BOOK_REQUEST}
    }

    function success() {
        return {type: bookingConstants.BOOKING_BOOK_SUCCESS}
    }

    function failure(error) {
        return {type: bookingConstants.BOOKING_BOOK_FAILURE, error}
    }
}

function clear() {
    return dispatch => dispatch({type: bookingConstants.BOOKING_CLEAR});
}
