import React from 'react';
import {Link} from 'react-router-dom';
import config from '../../config.json';

const Step3 = () => {
    return (
        <div className="form">
            <div className="form-wrapper">
                <div className="booking-logo-form">
                    <a href="/"><img alt="logo"/></a>
                </div>
                <p className="form-step">KROK 3/3</p>
                <h1 className="form-title">Dziękujemy za&nbsp;wypełnienie formularza!</h1>
                <p className="form-hint">
                    To już ostatni krok. Daj nam chwilę na zweryfikowanie danych. Jeśli wszystko poszło zgodnie z
                    planem, w Twojej skrzynce mailowej za chwilę pojawią się szczegółowe informacje odnośnie rezerwacji
                    i opłat.
                </p>
                <label className="contact-label">Jakieś pytania?</label>
                <hr/>
                <div className="contact">
                    <img src="../img/ic_phone.svg" alt="tel"/><a href={`tel:${config.phoneNoSpaces}`}>{config.phone}</a>
                </div>
                <hr/>
                <div className="contact">
                    <img src="../img/ic_mail.svg" alt="email"/><a href={`mailto:${config.email}`}>{config.email}</a>
                </div>
                <hr/>
            </div>
            <div className="buttons-wrapper">
                <Link to="/" className="btn btn-support1 full-width">Wróć do strony głównej</Link>
            </div>
        </div>
    );
};

export default Step3;
