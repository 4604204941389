import React from 'react';
import Input from "./form/Input";
import TextArea from "./form/TextArea";
import CheckBox from "./form/CheckBox";
import {errors} from "./errors";
import validator from "validator";

class Step2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validate: false,
        }
        this.validate = this.validate.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }

    validate() {
        const fields = {};
        fields["firstName"] = !this.props.firstName;
        fields["lastName"] = !this.props.lastName;
        fields["email"] = !this.props.email;
        fields["phone"] = !this.props.phone;
        fields["termsOfUse"] = !this.props.termsOfUse;
        fields["privacyPolicy"] = !this.props.privacyPolicy;
        if (Object.values(fields).some(it => it)) {
            return {
                message: errors.MISSING_REQUIRED_FIELDS,
                fields: fields,
            }
        }
        if (!validator.default.isEmail(this.props.email)) {
            fields["email"] = true;
            return {
                message: errors.INCORRECT_EMAIL_ADDRESS,
                fields: fields,
            }
        }
        if (!validator.default.isMobilePhone(this.props.phone)) {
            fields["phone"] = true;
            return {
                message: errors.INCORRECT_PHONE_NUMBER,
                fields: fields,
            }
        }
        return null;
    }

    handleNextClick() {
        if (!this.validate()) {
            this.props.onNextClick()
        } else {
            this.setState({validate: true})
        }
    }

    render() {
        const {
            firstName,
            lastName,
            email,
            phone,
            message,
            termsOfUse,
            privacyPolicy,
            loading,
            onInputChange,
            onPreviousClick,
        } = this.props;
        const error = this.state.validate ? this.validate() : null;
        return (
            <div className="form">
                <div className="form-wrapper">
                    <div className="booking-logo-form">
                        <a href="/"><img alt="logo"/></a>
                    </div>
                    <p className="form-step">KROK 2/3</p>
                    <h1 className="form-title">Wypełnij formularz</h1>
                    <p className="form-hint">
                        Powiedz nam trochę więcej o Twoich planach wypoczynkowych - my zajmiemy się resztą.
                    </p>
                    {error &&
                    <p className="form-error">{error.message}</p>
                    }
                    <Input
                        label="Imię"
                        type="text"
                        name="firstName"
                        placeholder="Twoje imię"
                        value={firstName}
                        error={error?.fields["firstName"]}
                        onChange={onInputChange}/>
                    <Input
                        label="Nazwisko"
                        type="text"
                        name="lastName"
                        placeholder="Twoje nazwisko"
                        value={lastName}
                        error={error?.fields["lastName"]}
                        onChange={onInputChange}/>
                    <Input
                        label="Email"
                        type="text"
                        name="email"
                        placeholder="Twój email"
                        value={email}
                        error={error?.fields["email"]}
                        onChange={onInputChange}/>
                    <Input
                        label="Telefon"
                        type="text"
                        name="phone"
                        placeholder="Twój numer"
                        value={phone}
                        error={error?.fields["phone"]}
                        onChange={onInputChange}/>
                    <TextArea
                        label="Wiadomość"
                        name="message"
                        placeholder="Twoja wiadomość"
                        value={message}
                        onChange={onInputChange}/>
                    <CheckBox
                        name="termsOfUse"
                        checked={termsOfUse}
                        error={error?.fields["termsOfUse"]}
                        onChange={onInputChange}>
                        Oświadczam, iż zapoznałem się z <a href="/terms-and-conditions"
                                                           target="_blank">Regulaminem</a> Narwiańskiej Ostoi
                        oraz i zgadzam się przestrzegać go podczas najmu
                    </CheckBox>
                    <hr/>
                    <CheckBox
                        name="privacyPolicy"
                        checked={privacyPolicy}
                        error={error?.fields["privacyPolicy"]}
                        onChange={onInputChange}>
                        Akceptuje <a href="/privacy-policy" target="_blank">Politykę Prywatności</a> oraz zasady ochrony
                        danych osobowych.
                    </CheckBox>
                </div>
                <div className="buttons-wrapper">
                    <button className="btn btn-secondary left" onClick={onPreviousClick}>
                        Cofnij
                    </button>
                    <button className="btn btn-support1 right" onClick={this.handleNextClick}>
                        {loading &&
                        <span className="spinner-border" role="status" aria-hidden="true"></span>
                        }
                        {!loading &&
                        "Zarezerwuj"
                        }
                    </button>
                </div>
            </div>
        );
    }
}

export default Step2;
