import React from 'react';

const TextArea = props => {
    return (
        <div className="form-group">
            <label>{props.label}</label>
            <textarea
                className="form-control"
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}/>
        </div>
    );
};

export default TextArea;