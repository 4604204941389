import {bookingConstants} from '../constants';
import Moment from "moment";

const initialState = {
    loading: false,
    unavailableDays: null,
    booked: false,
    error: null,
}

export function booking(state = initialState, action) {
    switch (action.type) {
        case bookingConstants.BOOKING_GET_DAYS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case bookingConstants.BOOKING_GET_DAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                unavailableDays: action.unavailableDays.map(it => Moment(it).toDate()),
            };
        case bookingConstants.BOOKING_GET_DAYS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case bookingConstants.BOOKING_BOOK_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case bookingConstants.BOOKING_BOOK_SUCCESS:
            return {
                ...state,
                loading: false,
                booked: true,
            };
        case bookingConstants.BOOKING_BOOK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case bookingConstants.BOOKING_CLEAR:
            return initialState;
        default:
            return state
    }
}