import React from 'react';

const Input = props => {
    return (
        <div className={props.error ? "form-group error" : "form-group"}>
            <label>{props.label}</label>
            <input
                className="form-control"
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}/>
        </div>
    );
};

export default Input;