import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import '../../scss/booking.scss';
import { connect } from "react-redux";
import Carousel from "./Carousel";
import { bookingActions } from "../../actions";
import Moment from "moment";

class Book extends React.Component {

    constructor(props) {
        super(props);
        this.props.dispatch(bookingActions.getBookedDays(Moment().format("YYYY-MM-DD")))
        this.state = {
            step: 1,
            error: null,
            checkIn: null,
            checkOut: null,
            adultsCount: 0,
            childrenCount: 0,
            babiesCount: 0,
            petsCount: 0,
            sauna: false,
            price: null,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            termsOfUse: false,
            privacyPolicy: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.handleGuestsChange = this.handleGuestsChange.bind(this);
        this.handleSaunaChange = this.handleSaunaChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.goToStep1 = this.goToStep1.bind(this);
        this.goToStep2 = this.goToStep2.bind(this);
        this.goToStep3 = this.goToStep3.bind(this);
    }

    goToStep1() {
        this.setState({ step: 1 });
    }

    goToStep2() {
        this.setState({ step: 2 });
    }

    goToStep3() {
        const reservation = {
            checkInAt: Moment(this.state.checkIn).format("YYYY-MM-DD"),
            checkOutAt: Moment(this.state.checkOut).format("YYYY-MM-DD"),
            adultsCount: this.state.adultsCount + this.state.childrenCount,
            childrenCount: this.state.babiesCount,
            petsCount: this.state.petsCount,
            sauna: this.state.sauna,
            message: this.state.message,
            client: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
            }
        }
        this.props.dispatch(bookingActions.book(reservation))

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : (name !== "message" ? target.value.trim() : target.value);
        this.setState({
            [name]: value
        });
    }

    handleDatesChange(checkIn, checkOut) {
        this.setState({
            checkIn: checkIn,
            checkOut: checkOut,
        });
    }

    handleGuestsChange(adults, children, babies, pets) {
        this.setState({
            adultsCount: adults,
            childrenCount: children,
            babiesCount: babies,
            petsCount: pets,
        });
    }

    handleSaunaChange(checked) {
        this.setState({
            sauna: checked,
        });
    }

    handlePriceChange(price) {
        this.setState({
            price: price,
        });
    }

    renderStep() {
        if (this.props.booked) {
            this.props.dispatch(bookingActions.clear())
            this.setState({
                step: 3,
                error: false,
            });
            return <Step3 />;
        }
        switch (this.state.step) {
            case 2:
                return <Step2
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    email={this.state.email}
                    phone={this.state.phone}
                    message={this.state.message}
                    termsOfUse={this.state.termsOfUse}
                    privacyPolicy={this.state.privacyPolicy}
                    loading={this.props.loading}
                    error={this.state.error}
                    onInputChange={this.handleInputChange}
                    onPreviousClick={this.goToStep1}
                    onNextClick={this.goToStep3} />;
            case 3:
                return <Step3 />;
            default:
                return <Step1
                    unavailableDays={this.props.unavailableDays}
                    checkIn={this.state.checkIn}
                    checkOut={this.state.checkOut}
                    adultsCount={this.state.adultsCount}
                    childrenCount={this.state.childrenCount}
                    babiesCount={this.state.babiesCount}
                    petsCount={this.state.petsCount}
                    sauna={this.state.sauna}
                    price={this.state.price}
                    loading={this.props.loading}
                    error={this.state.error}
                    onDatesChange={this.handleDatesChange}
                    onGuestsChange={this.handleGuestsChange}
                    onSaunaChange={this.handleSaunaChange}
                    onPriceChange={this.handlePriceChange}
                    onNextClick={this.goToStep2} />;
        }
    }

    render() {
        return (
            <div className="content">
                <Carousel />
                <div className="booking-logo">
                    <a href="/"><img src="/img/logo.svg" alt="logo" /></a>
                </div>
                {this.renderStep()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.booking;
}

const connected = connect(mapStateToProps)(Book);
export { connected as Book };
