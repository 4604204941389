import React from 'react';
import Slider from "react-slick";

class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imageSliderRef: null,
            infoSliderRef: null
        };
    }

    componentDidMount() {
        this.setState({
            imageSliderRef: this.imageSliderRef,
            infoSliderRef: this.infoSliderRef
        });
    }

    render() {
        const gotoPrev = () => this.state.infoSliderRef.slickPrev();
        const gotoNext = () => this.state.infoSliderRef.slickNext();
        const settings = {
            dots: false,
            speed: 3000,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
            fade: true,
            infinite: true,
            focusOnSelect: false,
        };
        return (
            <section id="gallery">
                <Slider
                    className="gallery-image-slider"
                    ref={slider => this.imageSliderRef = slider}
                    asNavFor={this.state.infoSliderRef}
                    {...settings}>
                    <img className="img2" alt="background"/>
                    <img className="img3" alt="background"/>
                    <img className="img4" alt="background"/>
                    <img className="img5" alt="background"/>
                    <img className="img14" alt="background"/>
                    <img className="img6" alt="background"/>
                    <img className="img7" alt="background"/>
                    <img className="img10" alt="background"/>
                    <img className="img13" alt="background"/>
                    <img className="img11" alt="background"/>
                    <img className="img8" alt="background"/>
                    <img className="img12" alt="background"/>
                    <img className="img9" alt="background"/>
                </Slider>
                <div className="arrows">
                    <button className="arrow arrow-prev" tabIndex={1} onClick={gotoPrev}><i/></button>
                    <button className="arrow arrow-next" onClick={gotoNext}><i/></button>
                </div>
                <Slider
                    className="gallery-info-slider"
                    ref={slider => this.infoSliderRef = slider}
                    asNavFor={this.state.imageSliderRef}
                    {...settings}>
                    <div className="gallery-info">
                        <h2>Salon</h2>
                        <p>
                            Strefa wypoczynku z wygodną sofą i funkcją spania codziennego
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Salon</h2>
                        <p>
                            Widok z kanapy na resztę parteru.
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Kuchnia</h2>
                        <p>
                            Bogato wyposażona kuchnia zachęca do samodzielnego i wspólnego gotowania. Smacznego!
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Piętro</h2>
                        <p>
                            Łóżko z wysuwanym drugim miejsce do spania, jeżeli przyjedżacie w większym gronie.
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Chillout</h2>
                        <p>
                            Zasmakuj się w dobrej kawie lub herbacie u boku dobrej książki.
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Sypialnia</h2>
                        <p>
                            Duże łóżko z widokiem na las. To tu dzieję się cała magia. Zasypiaj i wstawaj z widokiem na las.
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Sypialnia</h2>
                        <p>
                            Nie ma nic lepszego niż śniadanie do łóżka z pięknym widokiem na las.
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Taras</h2>
                        <p>
                            Bliskość z naturą. Jeszcze krok i jesteś na podwórku obok lasu i widoku na Narew. Brzmi dobrze?
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Górki</h2>
                        <p>
                            Widok na Dolinę Narwi to jedna z tych rzeczy dzięki którym czujesz się jak wa bajce. Nic tylko spacerować!
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Domek</h2>
                        <p>
                            Połączenie designu z naturą w bryle nowoczesnej stodoły. Zapraszamy!
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Sypialnia</h2>
                        <p>
                            Nie ma nic lepszego niż śniadanie do łóżka z pięknym widokiem na las.
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Domek</h2>
                        <p>
                            W zgodzie z naturą.
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Łazienka</h2>
                        <p>
                            Na koniec dnia warto odpocząć w wodzie. Duża wanna nam w tym pomoże!
                        </p>
                    </div>
                </Slider>
            </section>
        );
    }
}

export default Gallery;
