import React from 'react';
import Header from '../Landing/Header';
import Footer from '../Landing/Footer';

const Document = props => {
    return (
        <div>
            <section>
                <Header className="row relative"/>
            </section>
            <section id="document">
                {props.children}
            </section>
            <Footer/>
        </div>
    );
};

export default Document;