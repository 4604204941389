import React from 'react';
import {Link} from 'react-router-dom';
import config from '../../config.json';
import Header from "./Header";

class Lead extends React.Component {
    render() {
        return (
            <section id="lead">
                <div className="lead-main-wrapper">
                    <img className="lead-main-image" alt="background"/>
                    <Header/>
                    <div className="lead-main">
                        <div className="lead-main-content">
                            <h1 className="lead-title">Ostoja<br/><i>Narwiańska</i></h1>
                            <p className="lead-desc d-none d-sm-block">
                                Odkryj zielone miejsce na styku Podlasia&nbsp;i&nbsp;Mazowsza,<br/>
                                gdzie łatwo zapomnieć o&nbsp;troskach&nbsp;codzienności.
                            </p>
                            <p className="lead-desc d-sm-none">
                                Odkryj zielone miejsce na styku Podlasia&nbsp;i&nbsp;Mazowsza,
                                gdzie łatwo zapomnieć o&nbsp;troskach&nbsp;codzienności.
                            </p>
                            <Link to="/book" className="btn btn-support1 responsive">Zarezerwuj pobyt</Link>
                        </div>
                    </div>
                    <div className="lead-photo-desc d-none d-md-block">
                        <b>Na zdjęciu:</b> Łąki Ostoi Narwiańskiej. To tutaj zaczyna się prawdziwy odpoczynek.
                    </div>
                    <div className="lead-arrow">
                        <a href="#lead-intro"><img alt="slide down"/></a>
                    </div>
                </div>
                <div className="row">
                    <div id="lead-intro" className="col-xl-9 col-lg-10">
                        Z&nbsp;poszanowaniem dla przyrody i&nbsp;z&nbsp;uwzględnieniem dobrego designu -&nbsp;tak
                        właśnie powstała nasza przestrzeń. Niebanalne miejsce, które powstało z&nbsp;myślą
                        o&nbsp;ludziach, którzy kochają ładne rzeczy i&nbsp;bliskość natury. A&nbsp;jest naprawdę
                        blisko. Za oknem rozpościera się widok na las i&nbsp;malowniczą dolinę Narwi. Gotowi na
                        prawdziwy odpoczynek?
                    </div>
                </div>
                <div className="row lead-map d-none d-sm-block">
                    <div className="col-xl-4 col-lg-6 col-sm-9 offset-xl-8 offset-lg-6 offset-sm-3">
                        <div className="row">
                            <div className="col-7 p4 lead-map-info">
                                Ostoja mieści się w samym centrum Zielonych Płuc Polski - Dolinie Narwi. 140 km
                                od Warszawy, 13 km od Łomży. Idealny punkt na mapie do dalszego eksplorowania Mazur,
                                Ziemi Łomżyńskiej i Biebrzy.<br/>
                                <a href={config.googleMapsUrl} target="_blank" rel="noreferrer">Zobacz w Google Maps</a>
                            </div>
                            <div className="col-5 lead-map-img">
                                <img alt="map"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row lead-map d-sm-none">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 lead-map-img">
                                <img alt="map"/>
                            </div>
                            <div className="col-12 lead-map-info">
                                Ostoja mieści się w samym centrum Zielonych Płuc Polski - Dolinie Narwi. 140 km
                                od Warszawy, 13 km od Łomży. Idealny punkt na mapie do dalszego eksplorowania Mazur,
                                Ziemi Łomżyńskiej i Biebrzy.<br/>
                                <a href={config.googleMapsUrl} target="_blank" rel="noreferrer">Zobacz w Google Maps</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Lead;
