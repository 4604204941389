import React from 'react';

const Price = props => {
    return (
        <div className={props.value != null ? "price" : "price d-none"}>
            <div className="price-left">
                <p className="price-title">Płatność:</p>
                <p className="price-hint">podana kwota to kwota brutto</p>
            </div>
            <div className="price-right">
                <p className="price-value">{props.value}&nbsp;zł</p>
            </div>
        </div>
    );
};

export default Price;