import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import React from 'react';
import {store} from './store';
import {history} from './history';
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'react-router-redux';
import {Book} from "./components/Booking";
import Landing from "./components/Landing";
import "./scss/styles.scss"
import {PrivacyPolicyDocument, TermsAndConditionsDocument} from "./components/Documents";

ReactDOM.render((
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route exact path="/" component={Landing}/>
                <Route path="/book" component={Book}/>
                <Route path="/terms-and-conditions" component={TermsAndConditionsDocument}/>
                <Route path="/privacy-policy" component={PrivacyPolicyDocument}/>
            </Switch>
        </ConnectedRouter>
    </Provider>

), document.getElementById('root'));
