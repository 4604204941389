import React from 'react';
import Lead from './Lead';
import Gallery from './Gallery';
import Features from './Features';
import Pricing from './Pricing';
import Footer from './Footer';

import '../../scss/landing.scss';

const Landing = () => {
    return (
        <div>
            <Lead/>
            <Gallery/>
            <Features/>
            <Pricing/>
            <Footer/>
        </div>
    );
};

export default Landing;
