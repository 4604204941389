import React from 'react';

class Features extends React.Component {
    render() {
        return (
            <section id="features">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="row features-text">
                            Zobacz, za co pokochasz naszą przestrzeń.
                        </div>
                    </div>
                    <div className="col-lg-8 col-sm-12 features-list">
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <h2 className="feature-title no1">Cisza, za którą tęskniszi</h2>
                                <p className="feature-desc">
                                    Szum drzew, śpiew ptaków i powiew wiatru - przygotuj się na symfonię napisaną przez
                                    samą
                                    Matkę Naturę. A zgiełk miasta? Szybko tutaj o nim zapomnisz. Zrozumiemy, jeśli
                                    będziesz
                                    chciał zostać u nas na dłużej.
                                </p>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <h2 className="feature-title no2">Malownicze okolice</h2>
                                <p className="feature-desc">
                                    Zielona Płuca Polski to idealne miejsce, żeby na nowo złapać kontakt z naturą.
                                    Bliskość Biebrzańskiego Parku Narodowego i Łomżyńskiego Parku Krajobrazowego to
                                    dodatkowe atuty. To także idealna baza wypadowa do dalszego eksplorowania tej
                                    nieodkrytej części Polski.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <h2 className="feature-title no3">Komfort na sześć</h2>
                                <p className="feature-desc">
                                    I dla sześciu. 70m2 otwartej przestrzeni z łazienką, sypialnią i antresolą wprost
                                    stworzoną do poobiednich drzemek. A skoro o obiadach mowa - w pełni wyposażona
                                    kuchnia
                                    poleca się do kulinarnych eksperymentów naszych gości.
                                </p>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <h2 className="feature-title no4">Odpoczynek bez ograniczeń</h2>
                                <p className="feature-desc">
                                    3,5 hektara działki, a na niej łąki, lasy, górki. Wszystko, czego potrzebujesz, żeby
                                    na
                                    nowo poczuć łączność z naturą. A może odwiedzisz nas na wiosnę? Odwilże i wylewająca
                                    rzeka tworzą jezioro, które dodaje miejscu dodatkowego uroku
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <h2 className="feature-title no5">Minimalistyczna przestrzeń</h2>
                                <p className="feature-desc">
                                    Otwarte pomieszczenia zaprojektowaliśmy z myślą o fanach dobrego designu i
                                    nieszablonowych rozwiązań. Przeszklone fasada pozwala zasypiać i budzić się z
                                    widokiem na las.
                                </p>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <h2 className="feature-title no6">Z poszanowaniem tradycji</h2>
                                <p className="feature-desc">
                                    Zaledwie 15 km od Ostoji zaczynają się Kurpie. Nie zapomnieliśmy o tym projektując
                                    nasz
                                    domek. Elementy charakterystycznych kurpiowskich wycinanek wkomponowaliśmy w
                                    minimalistyczną przestrzeń. Wyszło tak, jak lubimy - nieszablonowo!
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <h2 className="feature-title no7">Wystarczająco daleko, żeby odpocząć</h2>
                                <p className="feature-desc">
                                    i wystarczająco blisko, żeby odwiedzić nas na kilka chwil. 140 km od Warszawy, 13 km
                                    od
                                    Łomży - plan weekendowej ucieczki za miasto dawno nie był tak prosty i osiągalny.
                                    Nie
                                    możemy obiecać, że po tym weekendzie będziesz chciał wracać.
                                </p>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <h2 className="feature-title no8">Halo, centrala?</h2>
                                <p className="feature-desc">
                                    Nasz domek to także miejsce, w którym możesz zaszyć się podczas pracy zdalnej. Sieć
                                    dostępna na miejscu pozwoli Ci na wysyłanie maili, ale także na oglądanie Netflixa.
                                    Chociaż wyglądając za okno szczerze wątpimy, że będziesz miał na to ochotę.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Features;
