import Slider from "react-slick";
import React from "react";

const Carousel = () => {
    const settings = {
        dots: false,
        speed: 3000,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 6000,
        fade: true,
    };
    return (
        <div className="carousel">
            <Slider {...settings}>
                <div className="carousel-item">
                    <img className="img2" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Salon. Strefa wypoczynku z wygodną sofą i funkcją spania codziennego
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img3" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Salon. Widok z kanapy na resztę parteru.
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img4" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Kuchnia. Bogato wyposażona kuchnia zachęca do samodzielnego i wspólnego gotowania.
                            Smacznego!
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img5" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Piętro. Łóżko z wysuwanym drugim miejsce do spania, jeżeli przyjedżacie w większym gronie.
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img14" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Chillout. Zasmakuj się w dobrej kawie lub herbacie u boku dobrej książki.
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img6" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Sypialnia. Duże łóżko z widokiem na las. To tu dzieję się cała magia. Zasypiaj i wstawaj z
                            widokiem na las.
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img7" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Sypialnia. Nie ma nic lepszego niż śniadanie do łóżka z pięknym widokiem na las.
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img10" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Taras. Bliskość z naturą. Jeszcze krok i jesteś na podwórku obok lasu i widoku na Narew.
                            Brzmi dobrze?
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img13" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Górki. Widok na Dolinę Narwi to jedna z tych rzeczy dzięki którym czujesz się jak wa bajce.
                            Nic tylko spacerować!
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img11" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Domek. Połączenie designu z naturą w bryle nowoczesnej stodoły. Zapraszamy!
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img8" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Sypialnia. Nie ma nic lepszego niż śniadanie do łóżka z pięknym widokiem na las.
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img12" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Domek. W zgodzie z naturą.
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="img9" alt="background"/>
                    <div className="info">
                        <p className="p4">
                            Łazienka. Na koniec dnia warto odpocząć w wodzie. Duża wanna nam w tym pomoże!
                        </p>
                    </div>
                </div>
            </Slider>
        </div>
    );
}

export default Carousel;