import React from 'react';
import Document from "./Document";

export const TermsAndConditionsDocument = () => {
    return (
        <Document>
                <h1 className="document-title">Regulamin<br/>Ostoi</h1>
                <p className="document-content">
                    1. Rezerwacja wybranego terminu jest potwierdzona dopiero przy wpłacie zadatku -50% kwoty pobytu. Pozostałe 50% kwoty pobytu powinno zostać opłacone przelewem bankowym na dzień przed planowanym przyjazdem. W przypadku nie opłacenia pozostałej kwoty gospodarz ma prawo do anulowania rezerwacji bez wypłaty wpłaconego wcześniej zadatku.<br/><br/>
                    2. Wpłacony zadatek  nie podlega zwrotowi. W przypadku anulowania pobytu do 30 dni przed przyjazdem wpłacony zadatek nie przepada. Jest możliwość przełożenia pobytu na inny dostępny termin. Gdy rezerwacja zostanie anulowana później niż 30 dni przed planowaną datą przyjazdu- wtedy zadatek przepada.<br/><br/>
                    3. Od gości pobierana jest kaucja zwrotna w wysokości 500 zł (jest to zabezpieczenie na poczet wyrządzonych przez gości ewentualnych szkód). Kaucja jest zwracana do 5 dni roboczych od daty wymeldowania.<br/><br/>
                    4. Przelewy powinny być wykonane na rachunek bankowy HEXA BANK 75 8762 0009 0012 1659 3000 0010 Tomasz Sztachański, a w tytule prosimy napisać imię i nazwisko oraz wybrany termin.<br/><br/>
                    5. Za wszelkie szkody wyrządzone w domku odpowiada wynajmujący.<br/><br/>
                    6. W przypadku wyrządzenia szkody/usterki gość zobowiązany jest niezwłocznie do powiadomienia o tym gospodarza. Brak powiadomienia skutkuje pobraniem z kaucji opłaty za wyrządzona szkodę lub pociągnięcia do odpowiedzialności cywilno-prawnej.<br/><br/>
                    7. Domek wynajmowany jest na min. 2 doby.<br/><br/>
                    8. W przypadku „długich weekendów” oraz okresów świątecznych i sylwestrowych mogą obowiązywać inne ceny.<br/><br/>
                    9. Doba obowiązuje od godziny 15 w dniu przyjazdu do godziny 11 w dniu wyjazdu.<br/><br/>
                    10. Gospodarz zastrzega sobie prawo do odwołania rezerwacji z przyczyn losowych niezależnych od niego wraz ze zwrotem wszelkich poniesionych kosztów.<br/><br/>
                    11. W całym domku obowiązuje bezwzględny zakaz palenia.<br/><br/>
                    12. Gdy wynajmujący nie zastosuje się do powyższych zasad może skutkować to pobraniem odpowiedniej kwoty z kaucji zależnej od wyrządzonych zniszczeń lub złamania zasad regulaminu.<br/><br/>
                    13. Ostoja Narwiańska nie może być wykorzystywana w celach komercyjnych i reklamowych bez zgody gospodarza.<br/><br/>
                    14. Siatka na antresoli nie jest do skakania<br/><br/>
                    15. Jednocześnie na antresoli powinno znajdować się maksymalnie dwie osoby
                </p>
        </Document>
    );
}