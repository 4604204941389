import React from 'react';
import config from "../../config.json";

const Header = (props) => {
    return (
        <div id="head" className="row" {...props}>
            <div className="col-2 col-md-5 head-contact left">
                <a href={config.instagramUrl} target="_blank" rel="noreferrer"><img className="logo" alt="instagram"/></a>
                <ul className="d-none d-md-block">
                    <li><b>T:</b> <a href={`tel:${config.phoneNoSpaces}`}>{config.phone}</a></li>
                    <li><b>M:</b> <a href={`mailto:${config.email}`}>{config.email}</a></li>
                </ul>
            </div>
            <div className="col-8 col-md-2 head-logo">
                <a href="/"><img className="logo" src="/img/logo.svg" alt="logo"/></a>
            </div>
            <div className="col-2 col-md-5 head-contact right">
                <a href={config.googleMapsUrl} target="_blank" rel="noreferrer"><img className="logo" alt="map"/></a>
                <ul className="d-none d-md-block">
                    <li><b>Adres:</b> Nagórki 45</li>
                    <li><a href={config.googleMapsUrl} target="_blank" rel="noreferrer">Zobacz w Google Maps</a></li>
                </ul>
            </div>
        </div>
    );
}

export default Header;
