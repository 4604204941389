import React from 'react';
import config from '../../config.json';

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <div className="row">
                    <div className="col-md-5 col-sm-5 col-xs-12">
                        <div className="row">
                            <div className="col-md-4 col-sm-12 footer-logo">
                                <img alt="logo"/>
                            </div>
                            <div className="col-md-8 col-sm-12 footer-address">
                                Ostoja Narwiańska<br/>Nagórki 45<br/>18-421 Piątnica (Koło Łomży)<br/>Woj. Podlaskie
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-7 col-xs-12">
                        <div className="row">
                            <div className="col-md-5 col-sm-12">
                                <ul className="no-images">
                                    <li><a href="/privacy-policy">Polityka Prywatności</a></li>
                                    <li><a href="/terms-and-conditions">Regulamin Ostoi</a></li>
                                </ul>
                            </div>
                            <div className="col-md-7 col-sm-12">
                                <ul>
                                    <li>
                                        <a href={config.facebookUrl}>
                                            <img src="../img/ic_facebook.svg" target="_blank" rel="noreferrer" alt="facebook"/>Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a href={config.instagramUrl}>
                                            <img src="../img/ic_instagram.svg" target="_blank" rel="noreferrer" alt="instagram"/>Instagram
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`tel:${config.phoneNoSpaces}`}>
                                            <img src="../img/ic_phone.svg" alt="phone"/>{config.phone}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`mailto:${config.email}`}>
                                            <img src="../img/ic_mail.svg" alt="email"/>{config.email}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-8 col-sm-12 footer-copy">
                        © Ostoja Narwiańska 2021. Wszelkie&nbsp;prawa&nbsp;zastrzeżone.
                    </div>
                    <div className="col-md-4 col-sm-12 footer-tomo">
                        <a href="https://tomostudio.pl">Design & Code: <img alt="tomo studio"/></a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Footer;
